const storagePrefix = 'fishvoy_';

const storage = {
  getToken: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}token`) as string);
    } catch (ex) {
      return '';
    }
  },
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, JSON.stringify(token));
  },
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem(`${storagePrefix}guide`);
  },

  getGuide: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}guide`) as any);
    } catch (ex) {
      return null;
    }
  },
  setGuide: (guide: any) => {
    window.localStorage.setItem(`${storagePrefix}guide`, JSON.stringify(guide));
  },
  clearGuide: () => {
    window.localStorage.removeItem(`${storagePrefix}guide`);
  },
  switchDisplayMode: (isUserMode: boolean) => {
    window.localStorage.setItem(`${storagePrefix}userMode`, isUserMode ? 'Y' : 'N');
  },
  isUserDisplayMode: () => {
    try {
      return window.localStorage.getItem(`${storagePrefix}userMode`) === 'Y' ? true : false;
    } catch (ex) {
      return false;
    }
  },
  clearDisplayMode: () => {
    try {
      window.localStorage.removeItem(`${storagePrefix}userMode`);
    }
    catch {}
  },
  getPendingReview : () => {
    try {
      return JSON.parse(window.localStorage.getItem(`${storagePrefix}review`) as any);
    } catch (ex) {
      return null;
    }
  },
  addPendingReview : (review: any) => {
    window.localStorage.setItem(`${storagePrefix}review`, JSON.stringify(review));
  },
  clearPendingReview: () => {
    try {
      window.localStorage.removeItem(`${storagePrefix}review`);
    }
    catch {}
  },
  setNoOfPassenger : (num: number) => {
    window.localStorage.setItem(`${storagePrefix}noOfPassenger`, num.toString());
  },
  getNoOfPassenger: () => {
    try {
      return parseInt(window.localStorage.getItem(`${storagePrefix}noOfPassenger`) as any);
    } catch (ex) {
      return 1;
    }
  }
};

export default storage;
