import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  CheckBoxField,
  FileSelectionField,
  MultiSelectField,
  NumberField,
  RadioField,
  SelectField,
  StaticDataSelectField,
  TextField,
} from '../../../components/Form';
import useBoatHook from '../../../services/Boat/boatService';
import { dirtyValues } from '../../../services/utils';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate, useParams } from 'react-router-dom';
import useOptionData from '../../../lib/optionData';
import { ModelStatus, PresentYourBoat, presentYourBoatSchema } from '../../../services/Guide/guide';
import useGuideOptionsHook from '../../../services/guideOptionsHook';
interface InputProps {
  edit: boolean;
  guideId?: number;
}

export const FormBoat: React.FC<InputProps> = ({ edit, guideId }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const {
    useBoatTypes: boatTypes,
    useEngineTypes: typeofEngine,
    useNavigationEquipments: navEquipments,
    useSafetyEquipments: safetyEquipment,
    useFishingGears: fishingGearOnBoard,
    useCrew: additionalCrewOnBoard,
    useFacilities: facilities,
  } = useGuideOptionsHook();

  const {
    boatLength,
    boatCapicity,
    numberOfEngine,
    maxCruisingSpeed,
    wheelchairAccessibleOptions,
  } = useOptionData();

  const backHandler = () => {
    navigate('/boats');
  };

  const { postBoat, updateBoat, getBoat } = useBoatHook({ id, guideId });
  const { data } = getBoat;
  const { mutateAsync: mutateAsyncPost } = postBoat;
  const { mutateAsync: mutateAsyncUpdate } = updateBoat;
  const navigate = useNavigate();

  const methods = useForm<PresentYourBoat>({
    mode: 'all',
    defaultValues: { status: ModelStatus.New, wheelchairAccessible: false },
    resolver: yupResolver<PresentYourBoat>(presentYourBoatSchema),
  });
  const { handleSubmit, formState, reset } = methods;

  useEffect(() => {
    if (data) {
      reset(data);
    }
  }, [data, reset]);

  const onSubmit = (data: PresentYourBoat) => {
    data.guide = guideId;
    if (data.id) {
      let dirtyFields = dirtyValues(formState.dirtyFields, data);
      if (Object.keys(dirtyFields).length) {
        dirtyFields.id = data.id;
        mutateAsyncUpdate(dirtyFields, {
          onSuccess: () => {
            NotificationManager.success(t('notification.boat.saved'));
            navigate('/boats');
          },
          onError: () => {
            NotificationManager.error(t('notification_messages.error'));
          },
        });
      } else {
        NotificationManager.success(t('notification.boat.saved'));
        navigate('/boats');
      }
    } else {
      mutateAsyncPost(data, {
        onSuccess: () => {
          NotificationManager.success(t('notification.boat.saved'));
          navigate('/boats');
        },
        onError: () => {
          NotificationManager.error(t('notification_messages.error'));
        },
      });
    }
  };

  const onErrors = (data: any) => {
    console.log(data);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
        <div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <div className="mt-2 mb-3">
                <h5>{t('boat.title')}</h5>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="form-group col-md-6">
              <SelectField
                className="product_select"
                name={`boatType`}
                placeholder={t('boat.fields.boatType.placeholder')}
                options={boatTypes}
              />
            </div>
            <div className="form-group col-md-6">
              <StaticDataSelectField
                className="product_select"
                name={`boatLength`}
                placeholder={t('boat.fields.boatLength.placeholder')}
                options={boatLength}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <TextField
                name={`boatManufacturer`}
                placeholder={t('boat.fields.boatManufacturer.placeholder')}
              />
            </div>
            <div className="form-group col-md-6">
              <StaticDataSelectField
                className="product_select"
                name={`guestCapacity`}
                placeholder={t('boat.fields.guestCapacity.placeholder')}
                options={boatCapicity}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <div className="mt-2 mb-3">
                <RadioField
                  label={t('boat.fields.wheelchairAccessible.label')}
                  name={`wheelchairAccessible`}
                  options={wheelchairAccessibleOptions}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <SelectField
                className="product_select"
                name={`typeofEngine`}
                placeholder={t('boat.fields.typeofEngine.placeholder')}
                options={typeofEngine}
              />
            </div>
            <div className="form-group col-md-6">
              <div className="mt-2 mb-3">
                <CheckBoxField name={`extraMotor`} label={t('boat.fields.extraMotor.label')} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <TextField
                name={`engineManufacturer`}
                placeholder={t('boat.fields.engineManufacturer.placeholder')}
              />
            </div>
            <div className="form-group col-md-6">
              <StaticDataSelectField
                className="product_select"
                name={`numberofEngines`}
                placeholder={t('boat.fields.numberofEngines.placeholder')}
                options={numberOfEngine}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-6">
              <NumberField
                name={`horsePowerPerEngine`}
                placeholder={t('boat.fields.horsePowerPerEngine.placeholder')}
              />
            </div>
            <div className="form-group col-md-6">
              <StaticDataSelectField
                className="product_select"
                name={`maxCruisingSpeed`}
                placeholder={t('boat.fields.maxCruisingSpeed.placeholder')}
                options={maxCruisingSpeed}
              />
            </div>
          </div>

          <div className="row mt-1">
            <div className="form-group col-md-12">
              <p className="mt-4">
                <h5>{t('boat.equipmentFacilitiesSettingTitle')}</h5>
              </p>

              <MultiSelectField
                className="product_select"
                name={`navEquipments`}
                placeholder={t('boat.fields.navEquipments.placeholder')}
                label={t('boat.fields.navEquipments.label')}
                options={navEquipments}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <MultiSelectField
                className="product_select"
                name={`safetyEquipments`}
                label={t('boat.fields.safetyEquipments.label')}
                placeholder={t('boat.fields.safetyEquipments.placeholder')}
                options={safetyEquipment}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <MultiSelectField
                className="product_select"
                name={`facilities`}
                label={t('boat.fields.facilities.label')}
                placeholder={t('boat.fields.facilities.placeholder')}
                options={facilities}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <MultiSelectField
                className="product_select"
                name={`fishingGears`}
                label={t('boat.fields.fishingGears.label')}
                placeholder={t('boat.fields.fishingGears.placeholder')}
                options={fishingGearOnBoard}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <MultiSelectField
                className="product_select"
                name={`additionalCrew`}
                label={t('boat.fields.additionalCrew.label')}
                placeholder={t('boat.fields.additionalCrew.placeholder')}
                options={additionalCrewOnBoard}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <p className="mt-4">
                <h5>{t('boat.boatMediaSettingTitle')}</h5>
              </p>

              <FileSelectionField
                name={`boatMedia`}
                label={t('boat.fields.boatMedia.label')}
                selectedImagePath={'boats'}
              />
            </div>
          </div>
          <div className="row mt-1">
            <div className="form-group col-md-12">
              <div className="mt-2 mb-3">
                <button
                  type="button"
                  className="btn previous previous_button"
                  onClick={backHandler}
                >
                  {t('common.cancel')}
                </button>
                <button type="submit" className="next btn btn-gold">
                  {t('common.save')}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};
