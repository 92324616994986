/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import { useLogout, useUser } from '../../../../../lib/auth';
import moment from '../../../../../lib/moment';
import { currentApi } from '../../../../../services/Db/dbInfo';
import {
  useGetUnreadConversation,
  useGetUserUnreadMessageCount,
} from '../../../../../services/Message/messageService';
import { emailUsername, getEmailInitials } from '../../../../../services/utils';
import { ButtonSpinner } from '../../../../Spinner/ButtonSpinner';
import './style.css';
import storage from '../../../../../utils/storage';

function Header(props: any) {
  const [checkedForConvo, setCheckedForConvo] = React.useState(false);
  const [isAuthenticated, setAuthenticated] = React.useState(false);
  const { mutate: logOutUser } = useLogout();

  const { t, i18n } = useTranslation();
  const { data: userInfo } = useUser();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const isUserDisplayMode = storage.isUserDisplayMode();

  React.useEffect(() => {
    if (userInfo) {
      setAuthenticated(true);
    }
  }, [userInfo]);

  React.useEffect(() => {
    if (!checkedForConvo && props.userDetails) {
      props.fetchMyConversations({ myId: props.userDetails.id });
      setCheckedForConvo(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.userDetails]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const signout = (e: any) => {
    // look later for better solution
    queryClient.clear();
    logOutUser({});
    navigate('/');
  };

  const user = userInfo;

  let isGuide: boolean = false;
  if (userInfo) {
    isGuide = userInfo.isGuide;
    if (isUserDisplayMode) {
      isGuide = false;
    }
  }
  const [expanded, setExpanded] = React.useState(false);
  const handleNavItemClick = () => {
    setExpanded(false);
  };

  const changeLang = (languageCode: string) => {
    i18n.changeLanguage(languageCode);
    if (languageCode === 'en') moment.locale('en-gb');
    else if (languageCode === 'de-DE') moment.locale('de');
    else moment.locale(languageCode);
    handleNavItemClick();
  };

  return (
    <header>
      <Navbar
        collapseOnSelect
        expanded={expanded}
        expand={'lg'}
        bg="light"
        variant="light"
        className="p-4 px-md-4 mb-3 navbar navbar-expand-lg fixed-top bg-white navbar-light border-bottom shadow-sm"
      >
        <Navbar.Brand>
          <NavLink to="/" className="nav-link" onClick={handleNavItemClick}>
            <img src="/img/fishvoyLogo.png" height={30} alt="Logo" />
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={(event) => {
            setExpanded(expanded ? false : true);
          }}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto" as={'ul'}>
            <li className="nav-item">
              <NavLink to="/" className="nav-link" onClick={handleNavItemClick}>
                {t('common.search_trips')}
              </NavLink>
            </li>
            <li className="nav-item mt-2 mb-0 ml-3 mr-3 seprator">|</li>
            {!isAuthenticated && (
              <>
                <li className="nav-item dropdown hideContent">
                  <Dropdown>
                    <Dropdown.Toggle as={Nav.Link} variant="Secondary" id="home-register">
                      {t('common.register')}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="dropdown-item pointer"
                        to="/register-guide"
                        onClick={handleNavItemClick}
                        as={NavLink}
                      >
                        {t('common.as_a_guide')}
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="dropdown-item pointer"
                        to="/register"
                        onClick={handleNavItemClick}
                        as={NavLink}
                      >
                        {t('common.as_a_user')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
                <li className="nav-item mt-2 mb-0 ml-3 mr-3 seprator">|</li>
              </>
            )}

            <>
              <li className="nav-item">
                <NavLink to="/news" className="nav-link" onClick={handleNavItemClick}>
                  {t('nav.newsroom')}
                </NavLink>
              </li>{' '}
              <li className="nav-item mt-2 mb-0 ml-3 mr-3 seprator">|</li>
            </>

            <>
              <li className="nav-item dropdown hideContent">
                <Dropdown>
                  <Dropdown.Toggle as={Nav.Link} variant="Secondary" id="home-about">
                    {t('common.about')}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      className="dropdown-item pointer"
                      to="/faq/guest"
                      onClick={handleNavItemClick}
                      as={NavLink}
                    >
                      {t('nav.faq_guest')}
                    </Dropdown.Item>
                    <Dropdown.Item
                      className="dropdown-item pointer"
                      to="/faq/captain"
                      onClick={handleNavItemClick}
                      as={NavLink}
                    >
                      {t('nav.faq_captain_guide')}
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="dropdown-item pointer"
                      to="/terms"
                      onClick={handleNavItemClick}
                      as={NavLink}
                    >
                      {t('nav.terms')}
                    </Dropdown.Item>

                    <Dropdown.Item
                      className="dropdown-item pointer"
                      to="/privacy"
                      onClick={handleNavItemClick}
                      as={NavLink}
                    >
                      {t('nav.privacy')}
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>{' '}
            </>

            {isAuthenticated && !isGuide && (
              <li className="nav-item">
                <NavLink to="/guides" className="nav-link" onClick={handleNavItemClick}>
                  {t('nav.becomeGuide')}
                </NavLink>
              </li>
            )}

            {isAuthenticated && isGuide && (
              <>
                <li className="nav-item mt-2 mb-0 ml-3 mr-3 seprator">|</li>
                <li className="nav-item">
                  <NavLink to="/dashboard" className="nav-link" onClick={handleNavItemClick}>
                    {t('nav.dashboard')}
                  </NavLink>
                </li>
              </>
            )}
          </Nav>
          <ul className="navbar-nav nav-flex-icons">
            {' '}
            {isAuthenticated && <Notifications isGuide={isGuide} />}
            <li
              className="nav-item dropdown langselctor"
              style={{ paddingTop: window.innerWidth > 990 && !isAuthenticated ? 5 : 0 }}
            >
              <Dropdown>
                <Dropdown.Toggle
                  as={Nav.Link}
                  variant="Secondary"
                  className="nav-link "
                  id="home-language-dropdown"
                >
                  {(i18n.language === 'en' ||
                    !['en', 'fr', 'sv', 'de', 'de-DE'].includes(i18n.language)) && (
                    <span className={`fi fi-gb`}></span>
                  )}
                  {i18n.language === 'fr' && <span className={`fi fi-fr`}></span>}
                  {i18n.language === 'sv' && <span className={`fi fi-se `}></span>}
                  {i18n.language === 'de-DE' && <span className={`fi fi-de`}></span>}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {i18n.language !== 'en' && (
                    <Dropdown.Item
                      className="dropdown-item pointer"
                      onClick={() => changeLang('en')}
                    >
                      <span className={`fi fi-gb`}></span> {' English'}
                    </Dropdown.Item>
                  )}

                  {/* */}
                  {i18n.language !== 'sv' && (
                    <Dropdown.Item
                      className="dropdown-item pointer"
                      onClick={() => changeLang('sv')}
                    >
                      <span className={`fi fi-se`}></span> {' Svenska'}
                    </Dropdown.Item>
                  )}

                  {/* 
                   
                    {i18n.language !== 'fr' && (
                    <Dropdown.Item
                      className="dropdown-item pointer"
                      onClick={() => changeLang('fr')}
                    >
                      <span className={`fi fi-fr`}></span> {' Français'}
                    </Dropdown.Item>
                  )}
                    {i18n.language !== 'de-DE' && (
                    <Dropdown.Item
                      className="dropdown-item pointer"
                      onClick={() => changeLang('de-DE')}
                    >
                      <span className={`fi fi-de`}></span>{' '}
                      {i18n.language === 'en' ? ' German' : ' Deutsch'}
                    </Dropdown.Item>
                  )}
*/}
                </Dropdown.Menu>
              </Dropdown>
            </li>
            {isAuthenticated ? (
              <li className="nav-item dropdown">
                <Dropdown>
                  <Dropdown.Toggle
                    as={Nav.Link}
                    variant="Secondary"
                    className="nav-link "
                    id="home-settting-dropdown"
                  >
                    <i className="fa fa-user" style={{ fontSize: '20px' }}></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown-menu drop-right-menu">
                    <Dropdown.Item as={NavLink} to="/dashboard" onClick={handleNavItemClick}>
                      {t('nav.dashboard')}
                    </Dropdown.Item>
                    <Dropdown.Item as={NavLink} to="/messages" onClick={handleNavItemClick}>
                      {t('nav.messages')}
                    </Dropdown.Item>
                    {!isGuide && (
                      <>
                        <Dropdown.Item
                          as={NavLink}
                          to="/favorites"
                          className="dropdown-item"
                          onClick={handleNavItemClick}
                        >
                          {t('nav.favorites')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={NavLink}
                          to="/setting"
                          className="dropdown-item"
                          onClick={handleNavItemClick}
                        >
                          {t('nav.settings')}
                        </Dropdown.Item>
                      </>
                    )}
                    {isGuide && (
                      <>
                        <Dropdown.Item
                          as={NavLink}
                          to="/listing"
                          className="dropdown-item"
                          onClick={handleNavItemClick}
                        >
                          {t('nav.myListing')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={NavLink}
                          to="/trip"
                          className="dropdown-item"
                          onClick={handleNavItemClick}
                        >
                          {t('nav.myTrips')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={NavLink}
                          to="/boats"
                          className="dropdown-item"
                          onClick={handleNavItemClick}
                        >
                          {t('nav.myBoats')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          as={NavLink}
                          to="/profile"
                          className="dropdown-item"
                          onClick={handleNavItemClick}
                        >
                          {t('nav.profile')}
                        </Dropdown.Item>
                      </>
                    )}{' '}
                    <Dropdown.Item onClick={signout}>{t('common.signout')}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            ) : (
              <li className="nav-item">
                <NavLink
                  to={!isAuthenticated ? '/signin' : '/settings'}
                  className="btn btn-gold"
                  onClick={handleNavItemClick}
                >
                  {!isAuthenticated ? t('common.signin') : user?.displayName}
                </NavLink>
              </li>
            )}
          </ul>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
}

function Notifications(props: any) {
  const { isGuide } = props;
  const { t } = useTranslation();
  const { data } = useGetUserUnreadMessageCount();

  let count = parseInt(data?.count) || 0;
  let showNotification = count > 0 ? true : false;

  return (
    <>
      <li className="nav-item dropdown">
        <Dropdown>
          <Dropdown.Toggle as={Nav.Link} variant="success" id="home-notification">
            <i
              className="fa fa-envelope fa-lg icon-grey"
              style={{ top: '0px', fontSize: '20px' }}
            ></i>
            {showNotification && <span className="num-count">{count}</span>}
          </Dropdown.Toggle>

          <Dropdown.Menu className="drop-right-menu" style={{ minWidth: '300px' }}>
            <div className="list-group-flush list-group">
              <a
                href="#"
                data-rb-event-key="#"
                className="text-center text-primary fw-bold border-bottom border-light border-1 py-3 nav-link"
                role="button"
              >
                {t('conversations.messages')}
              </a>
              {showNotification && <NotificationSummery isGuide={isGuide} />}
              {!showNotification && (
                <span className="text-center text-primary fw-bold py-3 ">
                  {t('conversations.no_message')}
                </span>
              )}
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </li>
    </>
  );
}

function NotificationSummery(props: any) {
  const { isGuide } = props;
  const { data, isLoading } = useGetUnreadConversation(isGuide);
  if (isLoading) return <ButtonSpinner />;

  return (
    <>
      {data &&
        data.data &&
        data.data.map((conversation: any) => (
          <a
            key={conversation.id}
            href={`/messages/${conversation.id}/${conversation?.guide?.id}`}
            data-rb-event-key="#"
            className="border-bottom border-light  border-1 list-group-item list-group-item-action"
          >
            {isGuide && (
              <div className="align-items-center row">
                <div className="col-auto col">
                  <span
                    className="initial-avatar rounded-circle d-flex align-self-start shadow-1-strong"
                    style={{ height: '50px', width: '50px' }}
                  >
                    {getEmailInitials(conversation?.last_message?.sender?.username)}
                  </span>
                </div>
                <div className="ps-0 ms--2 col">
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{ maxWidth: '148px', overflow: 'hidden' }}>
                      <h4 className="h6 mb-0 text-small">
                        {emailUsername(conversation?.buyer?.name)}
                      </h4>
                    </div>
                    <div className="text-end">
                      <small className="">
                        {' '}
                        {moment(conversation?.last_message?.sent_at).fromNow()}
                      </small>
                    </div>
                  </div>
                  <p className="font-small mt-1 mb-0">{conversation?.last_message?.message}</p>
                </div>
              </div>
            )}
            {!isGuide && (
              <div className="align-items-center row">
                <div className="col-auto col">
                  <img
                    style={{ height: '50px', width: '50px' }}
                    src={`${currentApi.fileBasePath}${conversation?.guide?.profilePhoto}`}
                    alt="User Avatar"
                    className="user-avatar lg-avatar rounded-circle"
                  />
                </div>
                <div className="ps-0 ms--2 col">
                  <div className="d-flex justify-content-between align-items-center">
                    <div style={{ maxWidth: '148px', overflow: 'hidden' }}>
                      <h4 className="h6 mb-0 text-small">{conversation?.guide?.name}</h4>
                    </div>
                    <div className="text-end">
                      <small className="">
                        {moment(conversation?.last_message?.sent_at).fromNow()}
                      </small>
                    </div>
                  </div>
                  <p className="font-small mt-1 mb-0">{conversation?.last_message?.message}</p>
                </div>
              </div>
            )}
          </a>
        ))}
    </>
  );
}

export default React.memo(Header);
