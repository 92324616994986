import { Accordion, Card, Col, Nav, Row, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useGetFAQCategoryByType } from '../../services/User/userService';
import FAQList from './components/FAQList';
import './style.css';

const FAQ_LIST_TYPE = {
  guest: 'Guest',
  captain: 'Captain / Guide',
};

const CONTAINER_STYLE = {
  backgroundColor: '#FFF',
  marginTop: '-90px',
  maxWidth: '960px',
  minHeight: '800px',
  paddingBottom: '200px',
};

function groupAndSumItems(items: Array<any>): Array<any> {
  const groupedItems: { [key: string]: any } = {};

  items.forEach(item => {
      const key = `${item.id}-${item.name}-${item.icon}-${item.sort_rank}`;
      if (!groupedItems[key]) {
          groupedItems[key] = {
              id: item.id,
              name: item.name,
              icon: item.icon,
              sort_rank: item.sort_rank,
              item_count: 0
          };
      }
      groupedItems[key].item_count += parseInt(item.item_count, 10);
  });

  return Object.values(groupedItems);
}

const FAQ = () => {
  const { t } = useTranslation();
  const { type } = useParams();
  let FAQ_TYPE = FAQ_LIST_TYPE.captain;

  if (type?.toLowerCase() === 'guest') {
    FAQ_TYPE = FAQ_LIST_TYPE.guest;
  } else if (type?.toLowerCase() === 'captain') {
    FAQ_TYPE = FAQ_LIST_TYPE.captain;
  }
  const { data, isLoading } = useGetFAQCategoryByType(FAQ_TYPE);

  return (
    <div className="info-page">
      <div
        className="container-fluid display_operator_image"
        style={{ backgroundImage: 'url("/img/image.jpg")' }}
      >
        <div className="row">
          <div className="display_operator-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center whitetext">
            <h6>{t('faqPage.subheading')}</h6>
            <h2 className="display_operator_name">{t('faqPage.heading')}</h2>
          </div>
        </div>
      </div>
      <div className="container" style={CONTAINER_STYLE}>
        <div className="row">
          <div className="col-xl-12 pa-0">
            <div className="faq-search-wrap bg-teal-light-3">
              <div className="container">
                <h2 className="text-white mb-20">{t('faqPage.search.heading')}</h2>
                <div className="form-group w-100 mb-2 pt-3">
                  <div className="input-group">
                    <input
                      className="form-control form-control-lg filled-input bg-white"
                      placeholder={t('faqPage.search.input')}
                      type="text"
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <span className="feather-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-arrow-right"
                          >
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                            <polyline points="12 5 19 12 12 19"></polyline>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isLoading && <ButtonSpinner />}

            {data && data.length > 0 && (
              <div className="container mt-sm-60 mt-30">
                <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                  <Row className="hk-row">
                    <Col xl={4} style={{ paddingBottom: '15px' }}>
                      <Card>
                        <Card.Header as={'h6'} style={{ padding: '15px' }}>
                          {t('faqPage.category')}
                        </Card.Header>
                        <Nav as={'ul'} className="flex-column list-group list-group-flush">
                          {groupAndSumItems(data)
                            .sort((a: any, b: any) => a.sort_rank - b.sort_rank)
                            .map((category: any, index: number) => (
                              <Nav.Item as={'li'} key={`Nav_Item_category_${category.id}`}>
                                <Nav.Link
                                  eventKey={index + 1}
                                  className="list-group-item d-flex align-items-center"
                                >
                                  {category.icon && <i className={`${category.icon}`}></i>}

                                  {category.name}
                                  <span className="badge badge-light badge-pill ml-15">
                                    {category.item_count}
                                  </span>
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                        </Nav>
                      </Card>
                    </Col>
                    <Col xl={8}>
                      <Tab.Content>
                        {data.map((category: any, index: number) => (
                          <Tab.Pane eventKey={index + 1}>
                            <Card>
                              <Card.Header as={'h3'} style={{ padding: '15px' }}>
                                {category.name}
                              </Card.Header>
                              <Accordion
                                defaultActiveKey="0"
                                className="accordion-type-2 accordion-flush"
                              >
                                <FAQList id={category.id} type={FAQ_TYPE} />
                              </Accordion>
                            </Card>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Col>
                  </Row>
                </Tab.Container>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
