import { useEffect, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import Header from '../Home/components/header';
import Footer from './components/Footer';
import Navbar from './components/Navbars';
import Sidebar from './components/Sidebar';

import { useTranslation } from 'react-i18next';
import { ContainerContextProvider } from '../../../context/ContainerContext';
import './style.css';

export interface RouteItem {
  name?: string;
  icon?: string;
  path: string;
  isGuide?: boolean;
  isUser?: boolean;
}

export function AdminRouter(t: any): RouteItem[] {
  return [
    {
      path: '/dashboard',
      name: `${t('nav.dashboard')}`,
      icon: 'nc-icon nc-bank',
    },
    {
      path: '/messages',
      name: `${t('nav.messages')}`,
      icon: 'nc-icon nc-email-85',
    },
    {
      path: '/listing',
      name: `${t('nav.myListing')}`,
      icon: 'nc-icon nc-globe',
      isGuide: true,
    },
    {
      path: '/trip',
      name: `${t('nav.myTrips')}`,
      icon: 'nc-icon nc-pin-3',
      isGuide: true,
    },

    {
      path: '/boats',
      name: `${t('nav.myBoats')}`,
      icon: 'icon ion-ios-boat ',
      isGuide: true,
    },
    {
      path: '/profile',
      name: `${t('nav.profile')}`,
      icon: 'nc-icon nc-settings-gear-65',
      isGuide: true,
    },
    {
      path: '/favorites',
      name: `${t('nav.favorites')}`,
      icon: 'nc-icon nc-favourite-28',
      isUser: true,
    },
    {
      path: '/setting',
      name: `${t('nav.settings')}`,
      icon: 'nc-icon nc-settings-gear-65',
    },
  ];
}

function AdminLayout(): JSX.Element {
  const mainPanel = useRef<HTMLDivElement>(null);
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    if (mainPanel.current) {
      mainPanel.current.scrollTop = 0;
    }
    if (document && document.scrollingElement) document.scrollingElement.scrollTop = 0;
  }, [location]);

  return (
    <ContainerContextProvider>
      <div className="admin-layout-container">
        <Header />
        <div className="wrapper">
          <Sidebar routes={AdminRouter(t)} bgColor={'white'} activeColor={'white'} />
          <div className="main-panel" ref={mainPanel}>
            <Navbar />
            <Outlet />
            <Footer fluid />
          </div>
        </div>
      </div>
    </ContainerContextProvider>
  );
}

export default AdminLayout;
