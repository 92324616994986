import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import axios from '../../lib/client';
import publicClient from '../../lib/publicClient';

import i18next from 'i18next';
import qs from 'qs';
import { ApiHandler } from '../Db/apiHandler';
import { currentApi } from '../Db/dbInfo';
import { User } from './user';

const queryKey = 'authenticated-user';

export const loginWithEmail = async ({ email, password }: { email: string; password: string }) =>
  await ApiHandler.loginWithEmail(email, password);

export const connectWithProvider = async (provider: string) =>
  await ApiHandler.connectWithProvider(provider);

export const setUserAsGuide = async (user: User) => await ApiHandler.setUserAsGuide(user);

export const authWithProvide = async (provider: string, search: string) =>
  await ApiHandler.authWithProvider(provider, search);

export const createAccount = async ({ email, password }: { email: string; password: string }) =>
  await ApiHandler.createAccount(email, password);

export const useForgotPassword = () => {
  return useMutation<any, Error, any>(
    async ({ email }) => {
      await publicClient
        .post(`${currentApi.forgotPassword}`, { email: email })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        //queryClient.invalidateQueries(['BookMark']);
      },
    }
  );
};

export const useResetPassword = () => {
  return useMutation<any, Error, any>(
    async ({ password, passwordConfirmation, code }) => {
      await publicClient
        .post(`${currentApi.resetPassword}`, { password, passwordConfirmation, code })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        //queryClient.invalidateQueries(['BookMark']);
      },
    }
  );
};

export const useChangePassword = () => {
  return useMutation<any, Error, any>(
    async ({ newPassword, currentPassword, confirmPassword }) => {
      await axios
        .post(`${currentApi.changePassword}`, {
          password: newPassword,
          currentPassword: currentPassword,
          passwordConfirmation: confirmPassword,
        })
        .then(function (response: any) {
          let result: any = response.data.data;
          return result;
        });
    },
    {
      retry: 0,
      onSuccess: () => {
        //queryClient.invalidateQueries(['BookMark']);
      },
    }
  );
};

export const logout = () => ApiHandler.logout();

export const getUser = () => ApiHandler.getMe();

export const useUpdateUserSetting = (userId: any) => {
  const queryClient = useQueryClient();
  return useMutation(
    async (data: any) => {
      await axios.post(`${currentApi.user}/${userId}`, data).then(function (response: any) {
        let result: any = response.data.data;
        return result;
      });
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries([queryKey]);
      },
    }
  );
};

export const useGetTermsPage = () => {
  return useQuery(
    ['terms'],
    async () => {
      return await publicClient.get(`/terms-page`).then(function (response: any) {
        return response.data.data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useGetPrivacyPage = () => {
  return useQuery(
    ['privacy'],
    async () => {
      return await publicClient.get(`/privacy-page`).then(function (response: any) {
        return response.data.data;
      });
    },
    { keepPreviousData: true }
  );
};

export const useGetFAQCategoryByType = (type: string) => {
  return useQuery(
    ['faq-categories', i18next.language, type],
    async () => {
      return await publicClient
        .get(
          `/faq-categories/getCategoryByType?type=${type}&locale=${i18next.language}
        `
        )
        .then(function (response: any) {
          return response.data;
        });
    },
    { keepPreviousData: true }
  );
};

export const useGetFAQItemsByCategoryId = (id: number, type: string) => {
  return useQuery(
    ['faq-categories-items', id, type, i18next.language],
    async () => {
      let queryParam: any = {
        filters: {
          type: {
            $contains: [type, 'Both'],
          },
          faq_category: id,
        },
        fields: ['id', 'Title', 'Body'],
        populate: {
          faq_category: {
            fields: ['id'],
          },
        },
      };
      const query = qs.stringify(queryParam, { encode: false });

      return await publicClient
        .get(`/faq-items?locale=${i18next.language}&${query}`)
        .then(function (response: any) {
          return response.data.data;
        });
    },
    { keepPreviousData: true }
  );
};
