import { useEffect } from 'react';
import { useUser } from '../../lib/auth';
import storage from '../../utils/storage';
import GuideDashboard from './components/GuideDashboard';
import UserDashboard from './components/UserDashboard';

function Dashboard() {
  const { data: userInfo } = useUser();
  const isUserDisplayMode = storage.isUserDisplayMode();
  const isGuide = (userInfo?.isGuide && !isUserDisplayMode) || false;
  const pendingReview = storage.getPendingReview();

  useEffect(()=> {
    if(pendingReview && pendingReview.redirectUrl){
      window.location.href = pendingReview.redirectUrl;
    }
  }, [pendingReview]);

  return <>{isGuide ? <GuideDashboard /> : <UserDashboard />}</>;
}

export default Dashboard;
