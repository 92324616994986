import * as _ from 'lodash';
import { useTranslation } from 'react-i18next';
import {
  EmailField,
  FileSelectionField,
  NumberField,
  PhoneField,
  SelectField,
  StaticDataMultiSelectField,
  StaticDataSelectField,
  TextField,
  TimezoneField,
} from '../../../../components/Form';
import i18n from '../../../../i18n';
import { currencyCodes } from '../../../../services/currencyCodes';
import useGuideOptionsHook from '../../../../services/guideOptionsHook';
import { getLanguages } from '../../../../services/languages';

const Info = (args: any) => {
  const { t } = useTranslation();
  const { useGuideTitle: guideTitles } = useGuideOptionsHook();
  const currencyCds = _.values(currencyCodes);
  let currencyTop: any = [];
  let currencySorted: any = [];

  currencyCds.forEach((c) => {
    if (
      c.code.includes('EUR') ||
      c.code.includes('SEK') ||
      c.code.includes('USD') ||
      c.code.includes('DKK') ||
      c.code.includes('GBP')
    ) {
      currencyTop.push(c);
    } else {
      currencySorted.push(c);
    }
  });
  currencyTop = currencyTop.concat(currencySorted);

  const currencyData = _.map(currencyTop, (value: any) => ({
    label: value.code,
    value: value.code,
  }));

  const languagesArray = getLanguages(i18n.language);
  const { fieldName } = args;

  return (
    <>
      <h5> {t('guide.profile.title')}</h5>
      <p className="mt-3">{t('guide.profile.description')}</p>
      <div className="row">
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.firstName`}
            placeholder={t('guide.fields.firstName.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <TextField
            name={`${fieldName}.lastName`}
            placeholder={t('guide.fields.lastName.placeholder')}
          />
        </div>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <EmailField
            name={`${fieldName}.email`}
            placeholder={t('guide.fields.email.placeholder')}
          />
        </div>
        <div className="form-group col-md-6">
          <PhoneField
            name={`${fieldName}.phoneNumber`}
            placeholder={t('guide.fields.phoneNumber.placeholder')}
          />
        </div>
      </div>
      <div className="row">
        <FileSelectionField
          name={`${fieldName}.profilePhoto`}
          label={t('guide.fields.profilePhoto.label')}
          maxNumber={1}
          selectedImagePath={'guide-profile'}
        />
      </div>

      <div className="mt-4">
        <p>{t('guide.profile.additionalSettingTitle')}</p>
      </div>
      <div className="row">
        <div className="form-group col-md-6">
          <SelectField
            className="product_select"
            name={`${fieldName}.title`}
            placeholder={t('guide.fields.title.placeholder')}
            options={guideTitles}
          />
        </div>
        <div className="form-group col-md-6">
          <StaticDataMultiSelectField
            className="product_select"
            name={`${fieldName}.languagesSpoken`}
            placeholder={t('guide.fields.languagesSpoken.placeholder')}
            options={languagesArray}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="form-group col-md-6">
          <StaticDataSelectField
            className="product_select"
            name={`${fieldName}.currency`}
            placeholder={t('guide.fields.currency.placeholder')}
            options={currencyData}
          />
        </div>
        <div className="form-group col-md-6">
          <NumberField
            name={`${fieldName}.experience`}
            max={100}
            placeholder={t('guide.fields.experience.placeholder')}
          />
        </div>
      </div>
      <div className="row mb-4">
        <div className="form-group col-md-6">
          <TimezoneField
            className="product_select"
            name={`${fieldName}.timeZone`}
            placeholder={t('guide.fields.timeZone.placeholder')}
          />
        </div>
      </div>
    </>
  );
};

export default Info;
