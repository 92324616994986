import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import NewWindow from 'react-new-window';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useUser } from '../../lib/auth';
import { GuideDetails, GuideDetailsSchema } from '../../services/Guide/guide';
import useGuideHook from '../../services/Guide/guideService';
import useStep from '../../services/useStep';
import { dirtyValues, scrollToTop } from '../../services/utils';
import Boats from './components/Boats';
import Info from './components/Info';
import ListingProfile from './components/ListingProfile';
import Trips from './components/Trips';
import SubscribeComponent from './components/SubscribeComponent';
import Register from './components/register';
import './style.css';

const steps = [
  { fieldName: 'guideProfile', Component: Info },
  { fieldName: 'businessProfile', Component: ListingProfile },
  { fieldName: 'presentYourBoats', Component: Boats },
  { fieldName: 'presentYourTrips', Component: Trips },
  { fieldName: 'subscription', Component: SubscribeComponent },
];

function Guides() {
  const { index, step, navigation } = useStep({ initialStep: 0, steps });
  const { fieldName, Component } = step;
  const { t } = useTranslation();
  const { postGuide, getLastDraftVersion, updateGuide, createGuideDetails } = useGuideHook({});
  const [isVisible, setIsVisible] = useState(true);
  const [isPreviewGuide, setPreviewGuide] = useState(false);
  const [isError, setError] = useState(false);
  const [showUSPs, setShowUSPs] = useState(true);

  const navigate = useNavigate();
  const { previous, next, go } = navigation;
  const { data: userInfo } = useUser();

  const props = {
    fieldName,
    isVisible,
    setIsVisible,
  };

  const { data, isLoading, refetch } = getLastDraftVersion;

  const { isLoading: isLoadingPost, mutateAsync: mutateAsyncPost } = postGuide;

  const { mutateAsync: mutateAsyncSaveDetail } = createGuideDetails;

  const { isLoading: isLoadingUpdate, mutateAsync: mutateAsyncUpdate } = updateGuide;

  const methods = useForm<GuideDetails>({
    mode: 'all',
    defaultValues: {
      guideProfile: {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      },
    },
    resolver: yupResolver<GuideDetails>(GuideDetailsSchema),
  });

  const {
    handleSubmit,
    getFieldState,
    setFocus,

    formState: { errors },
  } = methods;

  useEffect(() => {
    if (userInfo && userInfo.isGuide) {
      setShowUSPs(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    if (data) {
      methods.reset(data);
      setShowUSPs(false);
      if (index === 0) {
        if (data?.presentYourBoats?.length && data?.presentYourBoats?.length > 0) {
          go(3);
        } else if (data?.businessProfile?.businessName) {
          go(2);
        } else {
          go(1);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const scrollToElement = (fieldName: string) => {
    const element = document.querySelector(`[name="${fieldName}"]`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const onSubmit = () => {
    if (data?.id && data?.presentYourTrips && data?.presentYourTrips?.length > 0) {
      setError(false);
      mutateAsyncSaveDetail(
        { userId: userInfo?.id, guideId: data.id },
        {
          onSuccess: () => {
            NotificationManager.success(t('notification.guide.saved'));
            // setTimeout(() => {
            //   navigate('/dashboard', { state: { guideId: data.id } });
            // }, 1000);
            onGoTo(4);
          },
          onError: () => {
            NotificationManager.error(t('notification_messages.error'));
          },
        }
      );
    } else {
      setError(true);
    }
  };

  const onErrors = (data: any) => {
    console.log(data);
  };

  const onStepSubmitHandler = () => {
    methods.trigger(fieldName).then(() => {
      if (Object.keys(errors).length) {
        const errorFields = Object.keys(errors);
        errorFields.forEach((field: any) => {
          const data = getFieldState(field);
          if (data && data.error && Object.keys(data.error).length > 0) {
            const fieldData = Object.keys(data.error);
            const fieldName: string = `${field}.${fieldData[0]}`;
            setFocus(fieldName as any, { shouldSelect: true });
            scrollToElement(fieldName);
          }
        });
      }
      const { error } = methods.getFieldState(fieldName);
      if (!error) {
        scrollToTop();
        if (index === 0 || index === 1) {
          const values = methods.getValues();
          if (values.id) {
            let dirtyFields = dirtyValues(methods.formState.dirtyFields, values);
            if (Object.keys(dirtyFields).length) {
              dirtyFields.id = values.id;
              mutateAsyncUpdate(dirtyFields, {
                onSuccess: (data: any) => {
                  next();
                },
                onError: (data) => {
                  NotificationManager.error(t('notification_messages.error'));
                },
              });
            } else {
              next();
            }
          } else {
            mutateAsyncPost(values, {
              onSuccess: () => {
                refetch();
                next();
              },
              onError: (data) => {
                NotificationManager.error(t('notification_messages.error'));
              },
            });
          }
        } else {
          next();
        }
      }
    });
  };

  const onGoTo = (goToIndex: number) => {
    scrollToTop();
    methods.trigger().then(() => {
      const { error: currentFieldError } = methods.getFieldState(fieldName);
      if (currentFieldError) return;
      steps.forEach((step: any, index: number) => {
        const { error } = methods.getFieldState(step.fieldName);
        if (error) return;
        if (goToIndex === index) {
          go(goToIndex);
        }
      });
    });
  };

  const onBackHandler = () => {
    scrollToTop();
    previous();
  };

  const onPreview = () => setPreviewGuide(!isPreviewGuide);

  if (showUSPs) return <Register setShowUSPs={setShowUSPs} />;

  return (
    <section className="multi_step_form mt-5">
      <FormProvider {...methods}>
        <form id="msform" onSubmit={handleSubmit(onSubmit, onErrors)} noValidate>
          <ul id="progressbar">
            <li
              onClick={() => {
                onGoTo(0);
              }}
              className={index >= 0 ? 'active' : ''}
            >
              {t('guide.profile.header')}
            </li>
            <li
              onClick={() => {
                onGoTo(1);
              }}
              className={index >= 1 ? 'active' : ''}
            >
              {t('guide.businessProfile.header')}
            </li>
            <li
              onClick={() => {
                onGoTo(2);
              }}
              className={index >= 2 ? 'active' : ''}
            >
              {t('guide.boats.header')}
            </li>
            <li
              onClick={() => {
                onGoTo(3);
              }}
              className={index >= 3 ? 'active' : ''}
            >
              {t('guide.trips.header')}
            </li>
            <li
              onClick={() => {
                onGoTo(4);
              }}
              className={index >= 4 ? 'active' : ''}
            >
              {t('guide.subscribe.header')}
            </li>
          </ul>
          {index < 4 ? <fieldset>
            {isLoading ? (
              <ButtonSpinner />
            ) : (
              <>
                <Component {...props} />
                {isVisible && (
                  <>
                    {(!isLoadingPost || !isLoadingUpdate) && index >= 1 && index !== 4  && (
                      <button
                        type="button"
                        className="btn previous previous_button"
                        onClick={onBackHandler}
                      >
                        {t('common.back')}
                      </button>
                    )}
                    {index < 3 && (
                      <button
                        type="button"
                        className="next btn btn-gold"
                        onClick={onStepSubmitHandler}
                      >
                        {isLoadingPost || isLoadingUpdate ? (
                          <ButtonSpinner />
                        ) : (
                          t('common.continue')
                        )}
                      </button>
                    )}
                    {index === 3 && (
                      <>
                        {data &&
                          data?.id &&
                          data?.presentYourTrips &&
                          data?.presentYourTrips?.length > 0 && (
                            <button type="button" className="btn btn-gold" onClick={onPreview}>
                              {isPreviewGuide ? t('common.close_preview') : t('common.preview')}
                            </button>
                          )}
                        {isPreviewGuide &&
                          data &&
                          data?.presentYourTrips &&
                          data?.presentYourTrips?.length > 0 && (
                            <NewWindow
                              onUnload={onPreview}
                              title={t('common.preview')}
                              url={`/trip-info/${data?.presentYourTrips[0]?.id}?preview=true`}
                              center="parent"
                              features={{
                                left: 0,
                                top: 0,
                                width: window.innerWidth,
                                height: window.innerHeight,
                              }}
                              copyStyles={false}
                            ></NewWindow>
                          )}
                        <button type="button" className="btn btn-gold" onClick={onSubmit}>
                          {isLoadingPost || isLoadingUpdate ? (
                            <ButtonSpinner />
                          ) : (
                            t('common.submit')
                          )}
                        </button>
                        {isError &&
                          data?.presentYourTrips &&
                          data?.presentYourTrips?.length === 0 && (
                            <>
                              <div className="text-danger text-center">
                                {t('validation.atleast_one_trip')}
                              </div>
                            </>
                          )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </fieldset> : <Component {...props} guideId={data?.id}/>}
        </form>
      </FormProvider>
    </section>
  );
}

export default Guides;
