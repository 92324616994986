import { useEffect, useRef, useState } from 'react';
import { Container, Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useContainerContext } from '../../../../../context/ContainerContext';
import { useUser } from '../../../../../lib/auth';
import useGuideHook from '../../../../../services/Guide/guideService';
import './style.css';

const customStyles = {
  valueContainer: (provided: any) => ({
    ...provided,
    padding: '0px',
    margin: '0px',
    textAlign: 'left',
    minWidth: '150px',
  }),
  input: (provided: any) => ({
    ...provided,
    padding: '0px 2px',
    margin: '0px',
  }),

  control: (provided: any) => ({
    ...provided,
    borderWidth: '0px',
    minHeight: '48px',
    borderRadius: '0px',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    padding: '0px',
    textAlign: 'left',
  }),
};
function Navbars(): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: userInfo } = useUser();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [color, setColor] = useState<string>('transparent');
  const sidebarToggle = useRef<HTMLButtonElement>(null);
  const location = useLocation();
  const { guideId } = useParams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateColor = (): void => {
    if (window.innerWidth < 993 && isOpen) {
      setColor('dark');
    } else {
      setColor('transparent');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', updateColor);
    return () => {
      window.removeEventListener('resize', updateColor);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (window.innerWidth < 993 && document.documentElement.className.indexOf('nav-open') !== -1) {
      document.documentElement.classList.toggle('nav-open');
      sidebarToggle.current?.classList.toggle('toggled');
    }
  }, [location]);

  const { getListing } = useGuideHook({});
  const { data, isLoading } = getListing;
  const {
    state: { businessName, guideId: contextGuideId },
    actions: { setGuide },
  } = useContainerContext();

  function handleChange(obj: any): void {
    setGuide({ guideId: obj.value, businessName: obj.label });
  }

  useEffect(() => {
    if (data && data.length > 0) {
      let obj;
      if (guideId) {
        obj = data?.find((obj: any) => Number(guideId) === Number(obj.value)) || data[0];
      } else if (contextGuideId) {
        obj = data?.find((obj: any) => Number(contextGuideId) === Number(obj.value)) || data[0];
      } else {
        obj = data[0];
      }
      setGuide({ guideId: obj.value, businessName: obj.label });
    }

    if (!isLoading && data.length === 0 && userInfo?.isGuide) {
      navigate('/guides');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data, guideId]);

  return (
    <>
      <Navbar
        color={location.pathname.indexOf('full-screen-maps') !== -1 ? 'dark' : color}
        expand="lg"
        className={
          location.pathname.indexOf('full-screen-maps') !== -1
            ? ''
            : ` ${color === 'transparent' ? 'navbar-transparent ' : ''}`
        }
      >
        <div className="navbar-wrapper">
          <NavbarBrand href="#">
            {businessName ? `${t('nav.dashboard')} - ${businessName}` : `${t('nav.dashboard')}`}
          </NavbarBrand>
        </div>

        <Container className="justify-content-end" style={{ maxWidth: '100%' }}>
          <Nav className="justify-content-end">
            {!isLoading && data && data.length > 1 && (
              <Select
                options={data}
                onChange={(value) => handleChange(value)}
                defaultValue={
                  data?.find(
                    (obj: any) => Number(guideId || contextGuideId) === Number(obj.value)
                  ) || data[0]
                }
                styles={customStyles}
              />
            )}
          </Nav>
        </Container>
      </Navbar>
    </>
  );
}

export default Navbars;
