import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { ReviewStats } from '../../../components/Ratings';
import StarPicker from '../../../components/Ratings/components/StarsRating';
import { currentApi } from '../../../services/Db/dbInfo';
import {
  useGetAverageResponceTimeByGuideId,
  useGetGuideById,
} from '../../../services/Search/searchService';
import { getLanguageSpoken } from '../../../services/languages';

interface InputProps {
  guideId: number;
  tripId: number;
}
const GuideInfoCard = (props: InputProps) => {
  const { t } = useTranslation();
  const { data: guide, isLoading } = useGetGuideById(props.guideId);

  const { data: averageResponceTime, isLoading: isAverageResponceTimeLoading } =
    useGetAverageResponceTimeByGuideId(props.guideId);

  if (isLoading) {
    return <></>;
  }

  return (
    <>
      <div className="ratings-widget display_operator_shortinfo mb-4">
        <div className="author mb-4">
          <h4 style={{ marginBottom: '20px' }}>
            {t(`master_data.guide-title.${guide?.guideProfile?.title.value}`)}
          </h4>
          {guide?.guideProfile?.profilePhoto?.url && (
            <img
              className="avatar border-gray"
              src={`${currentApi.fileBasePath}${guide?.guideProfile?.profilePhoto?.url}`}
              alt="Operator Avatar"
            />
          )}
          <h6 className="title text-capitalize">{`${guide?.guideProfile?.firstName} ${guide?.guideProfile?.lastName}`}</h6>
        </div>
        <br />
        <div>
          <div className="title">
            <h6>{t('common.languages')}</h6>
          </div>
          <div>
            {guide && guide.guideProfile && guide.guideProfile.languagesSpoken && (
              <small>
                {getLanguageSpoken(guide?.guideProfile?.languagesSpoken, i18next.language)}
              </small>
            )}
          </div>
        </div>

        {guide && guide.guideProfile && guide.guideProfile.experience && (
          <>
            <br />
            <div>
              <div className="title">
                <h6>{t('common.experience')}</h6>
              </div>
              <div>
                <small>{guide?.guideProfile?.experience}</small>
              </div>
            </div>
          </>
        )}
        <hr />
        <br />
        <div style={{ marginBottom: '20px' }}>
          <h5 className="title text-capitalize">{guide?.businessProfile?.businessName}</h5>
          <div><small>{guide?.businessProfile?.businessDescription}</small></div>
        </div>
        <div>
          <div className="title">
            <h6 className="m-0">{t('rating.avg_rating')}</h6>
            <ReviewStats apiURL={currentApi.serverURL} slug={`${props.tripId}`} />
          </div>
          <br />
          {!isAverageResponceTimeLoading && (
            <>
              <div className="title">
                <h6 className="m-0">{t('conversations.average_response_time')}</h6>
              </div>
              <div>
                <StarPicker disabled={true} halfStars={true} value={averageResponceTime || 0} />(
                {averageResponceTime} {t('common.out_of')} 5) <br />
                <small>{t('conversations.reply_time')}</small>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default GuideInfoCard;
