import { useEffect, useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './style.css';

import { useTranslation } from 'react-i18next';
import MapViewModelDialog from '../../components/MapViewModelDialog';
import SearchForm from '../../components/SearchForm';
import useSticky from '../../lib/useSticky';
import { TripDetails } from '../../services/Guide/guide';
import {
  searchTripQueryParam,
  sortByQueryParam,
  useSearchTrips,
} from '../../services/Search/searchService';
import FilterBox from './FilterBox';
import ListTrip from './ListTrip';

function GuideSearch() {
  const { t } = useTranslation();
  const { city, placeId, noofpassanger, countryCode, long, lat, minLat, minLng, maxLat, maxLng } =
    useParams();
  const [isfilterVisible, setIsFilterVisible] = useState(false);
  const [mapView, setMapView] = useState(false);
  const [sortByTab, setSortByTab] = useState('recommended');
  const [filterTrip, setFilterTrip] = useState<number[]>([]);
  const [searchText, setSearchText] = useState('');

  const [queryParam, setQueryParam] = useState(
    searchTripQueryParam({
      city: city,
      noOfPassenger: Number(noofpassanger),
      countryCode: countryCode,
    })
  );

  const [stickyRef, sticky] = useSticky<HTMLDivElement>(200);

  useLayoutEffect(() => {
    window.scrollTo({ top: 180, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    setQueryParam(
      searchTripQueryParam({
        city: city,
        countryCode: countryCode,
        noOfPassenger: Number(noofpassanger),
      })
    );
    window.scrollTo({ top: 180, behavior: 'smooth' });
  }, [noofpassanger, city, countryCode, placeId]);

  const onFilterChage = (
    search?: string,
    targetedSpecies?: number[],
    fishingTechniques?: number[],
    fishingTypes?: number[],
    sortBy?: string
  ) => {
    setQueryParam(
      searchTripQueryParam({
        city: city,
        countryCode: countryCode,
        noOfPassenger: Number(noofpassanger),
        search,
        fishingTechniques,
        fishingTypes,
        targetedSpecies,
        sortBy: sortBy,
      })
    );
  };

  const onSortChange = (sortBy: string) => {
    setSortByTab(sortBy);
    setQueryParam(sortByQueryParam(sortBy, queryParam));
  };
  const { data: trips, isLoading } = useSearchTrips(queryParam);

  useEffect(() => {
    const list = trips && trips?.map((trip: TripDetails) => Number(trip.id));
    setFilterTrip(list || []);
  }, [trips]);

  const onSearchChange = (search: string) => {
    setSearchText(search);
  };

  return (
    <div className="container-fluid mt-5">
      {/* <!-- YOUR SEARCH ROW START--> */}
      <div className="row">
        <div className="display_operator-trips col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <h4 className=" text-center">{t('common.your_search')}</h4>

          <div className="searchpage_yoursearch mx-auto mt-4 search-page">
            <div className="row packages-form-header search-page__search">
              <SearchForm
                wrapperClass="container search-page__search__form"
                showlabels={true}
                inputWrapperClass="col-lg-4 col-md-6 col-sm-12 p-0"
                buttonText={t('common.search_change')}
                onSearchChange={onSearchChange}
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- YOUR SEARCH ROW SLUT--> */}
      <div className="row" id="123">
        <div
          className="display_operator-trips col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-1"
          style={{ paddingBottom: '0px' }}
        >
          <h2 className=" text-center" style={{ fontSize: 26 }}>
            {t(`common.search_result`)
              .replace('{length}', trips && trips.length > 0 ? trips.length.toString() : '0')
              .replace('{city}', searchText || placeId?.toString() || '')}
          </h2>
          <p id="result-1" className="hide">
            &nbsp;{' '}
          </p>
          <p className=" text-center">
            <a href="#changeSearch" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
              &#8593; {t(`common.search_change`)}
            </a>
          </p>
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 p-0">
          <main ref={stickyRef} className={`cd-main-content mt-4 ${sticky ? 'is-fixed' : ''}`}>
            <div className="cd-tab-filter-wrapper">
              <div className="cd-tab-filter">
                <ul className="cd-filters cd-filters-sort-by">
                  {t(`common.sort_by`)}:
                  <li className="placeholder">
                    <a data-type="all" href="#0">
                      {t('common.all')}
                    </a>
                  </li>
                  <li className="filter">
                    <a
                      className={sortByTab === 'recommended' ? 'selected' : ''}
                      href="#0"
                      data-type="all"
                      onClick={() => onSortChange('recommended')}
                    >
                      {t(`common.recommended`)}
                    </a>
                  </li>
                  <li className="filter" data-filter=".color-1">
                    <a
                      href="#0"
                      data-type="color-1"
                      onClick={() => onSortChange('rating')}
                      className={sortByTab === 'rating' ? 'selected' : ''}
                    >
                      {t(`common.review_score`)}
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className={isfilterVisible ? 'cd-gallery filter-is-visible' : 'cd-gallery'}>
              <ListTrip isLoading={isLoading} trips={trips} />
            </div>

            <div className={isfilterVisible ? 'cd-filter filter-is-visible' : 'cd-filter'}>
              <FilterBox onChage={onFilterChage} searchData={trips} />
              <a
                href="#0"
                onClick={() => setIsFilterVisible(!isfilterVisible)}
                className="cd-close"
              >
                X
              </a>
            </div>

            <a
              href="#0"
              onClick={() => setIsFilterVisible(!isfilterVisible)}
              className={
                isfilterVisible ? 'cd-filter-trigger filter-is-visible' : 'cd-filter-trigger'
              }
            >
              <i className="fa fa-sliders-h"></i> {t(`common.filters`)}
            </a>
            <a
              href="#0"
              className="cd-filter-trigger2 text-uppercase"
              onClick={() => setMapView(true)}
            >
              <i className="fa fa-map-marker" aria-hidden="true"></i> {t('common.viewmap')}
            </a>
          </main>
        </div>
      </div>
      {mapView && trips && trips.length > 0 && (
        <MapViewModelDialog
          show={mapView}
          onClose={() => setMapView(false)}
          center={[long, lat]}
          bbox={[minLng, minLat, maxLng, maxLat]}
          filterTrip={filterTrip}
        />
      )}
    </div>
  );
}

export default GuideSearch;
