import parse from 'html-react-parser';
import { Tab, Tabs } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ButtonSpinner } from '../../components/Spinner/ButtonSpinner';
import { useGetPrivacyPage } from '../../services/User/userService';

const Privacy = () => {
  const { t } = useTranslation();
  const { data, isLoading } = useGetPrivacyPage();

  return (
    <div className="info-page">
      <div
        className="container-fluid display_operator_image"
        style={{ backgroundImage: 'url("/img/image.jpg")' }}
      >
        <div className="row">
          <div className="display_operator-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center whitetext">
            <h6>{t('privacyPage.subheading')}</h6>
            <h2 className="display_operator_name">{t('privacyPage.heading')}</h2>
          </div>
        </div>
      </div>
      <div
        className="container"
        style={{ backgroundColor: '#FFF', marginTop: '-90px', maxWidth: '960px' }}
      >
        <div className="row">
          <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 p-5">
            <div className="display_operator_info mb-3">
              {isLoading && <ButtonSpinner />} {data && parse(data.description)}
            </div>
          </div>
        </div>

        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="tab">
                <Tabs id="controlled-tab-example" defaultActiveKey="visitors" className="mb-3">
                  <Tab eventKey="visitors" title={t('visitors')}>
                    {isLoading && <ButtonSpinner />} {data && parse(data.visitor)}
                  </Tab>
                  <Tab eventKey="guest" title={t('guestUser')}>
                    {isLoading && <ButtonSpinner />} {data && parse(data.guestUser)}
                  </Tab>
                  <Tab eventKey="operator" title={t('operator')}>
                    {isLoading && <ButtonSpinner />} {data && parse(data.operator)}
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
